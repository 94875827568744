/**

=========================================================
* Volt - Bootstrap 5 Admin Dashboard
=========================================================

* Product Page: https://themesberg.com/product/admin-dashboard/volt-bootstrap-5-dashboard
* Copyright 2020 Themesberg (https://www.themesberg.com)

* Designed and coded by https://themesberg.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. Please contact us to request a removal.

**/

@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,600,700,800,900&display=swap');

// Bootstrap mixins and functions
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/functions";

// Change variables here
@import "volt/variables";

// Bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap";

// Vendor
@import "volt/vendor";

// volt mixins & functions
@import "volt/mixins";
@import "volt/functions";

// Utilities
@import "volt/reboot";
@import "volt/utilities";

// Layout
@import "volt/layout";

// Components
@import "volt/components";

// write your custom styles here!

.navbar-border {
  border-bottom: 1px solid rgba(255,255,255,0.25)!important
}

.navbar.scrolled {
  background-color: #fff !important;
  @include transition(0.3s);
  box-shadow: 0 0 3px 0 rgb(0 0 0 / 22%);
  -moz-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.22);
  -webkit-box-shadow: 0 0 3px 0 rgb(0 0 0 / 22%);
}

.scrolled #logo-dark {
  display: block !important;
}

.scrolled #logo {
  display: none !important;
}

/*===========================
        07.TEAM css
===========================*/
.single_team {
  position: relative;
  min-height: 300px;
}

.single_team img {
  width: 100%;
  min-height: 300px;
  object-fit: cover;
}

.single_team .team_content {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  left: 0;
  bottom: 0;
  width: 85%;
  padding: 25px;
  padding-right: 60px;
}

.single_team .team_info {
  position: absolute;
  color: white;
  background-color: rgba(255, 0, 0, 0.8);
  right: 0;
  top: 0;
  padding: 5px 15px;
}

.single_team .team_content .team_name a {
  font-size: 20px;
  font-weight: 500;
  color: #2B2A3D;
  transition: all 0.3s ease-out 0s;
}

.single_team .team_content .team_name a:hover {
  color: #E84E4E;
}

.single_team .team_content p {
  margin-top: 5px;
}

.single_team .team_content .social {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.single_team .team_content .social li {
  margin: 3px 0;
}

.single_team .team_content .social li a {
  color: #5A5872;
  font-size: 16px;
  transition: all 0.3s ease-out 0s;
}

.single_team .team_content .social li a:hover {
  color: #E84E4E;
}

.form-group {
  margin-bottom: 1rem;
}

.table td.fit,
.table th.fit {
  white-space: nowrap;
  width: 1%;
}

.carousel-caption {
  top: 50%;
  transform: translateY(-50%);
  bottom: initial;
}
