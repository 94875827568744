@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import 'volt';

.stick {
  position: fixed;
  z-index: 1;
  top: 30vh;
  right: 0;
}

.corner {
  position: absolute;
  color: white;
  left: 0;
  bottom: 0;
  padding: 5px 15px;
}

.person {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.img-fare {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.img-cover {
  width: 100%;
  height: 260px;
  object-fit: cover;
}

.slide-cover {
  width: 100%;
  height: 600px;
  object-fit: cover;
}

.section-cover {
  width: 100%;
  height: 500px;
  object-fit: cover;
}

.testimony-cover {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.social-icon-lg {
  font-size: 1.5rem;
  line-height: 2.5rem;
}

.obscure {
  filter: brightness(0.7);
}

.card-body {
  min-height: 116px;
}
